<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="tabs">
      <div :class="['item', searchData.deviceType === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge>
          <span class="tab-name">安卓端</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.deviceType === 2 ? 'active' : '']" @click="handleClickTab(2)">
        <el-badge>
          <span class="tab-name">ios端</span>
        </el-badge>
      </div>
    </div>
    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="id" label="设备类型" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.deviceType == 1" type="primary" size="mini">安卓端</el-tag>
          <el-tag v-if="row.deviceType == 2" type="success" size="mini">ios端</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="充值金额" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="coin" label="萝卜币" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="160" show-overflow-tooltip fixed="right"> </el-table-column>
    </el-table>
    <!-- <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" /> -->
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI } from './api'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        deviceType: 1
      }
      // total: 0
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.list
    },
    handleClickTab(i) {
      if (this.searchData.deviceType == i) return
      this.searchData.deviceType = i
      this.getList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
